* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

#donation-button {
    width: 250px;
    height: 50px;
    cursor: pointer;

    position: absolute;
    z-index: 2;
    left: 50%;
    right: initial;
    bottom: 125px;
    margin-left: -125px;

    font-size: 20px;
    font-weight: bold;
    color: #082d47;
    background: white;
    border: 2px solid #082d47;
    box-shadow: 5px 5px 0 #082d47,
    -5px -5px 0 #082d47,
    -5px 5px 0 #082d47,
    5px -5px 0 #082d47;
    transition: 500ms ease-in-out;
}

#donation-button:hover {
    box-shadow: 20px 5px 0 #082d47, -20px -5px 0 #082d47;
}

#donation-button:focus {
    outline: none;
}

@media only screen and (max-height: 600px) {
    #donation-button {
        left: initial;
        right: 30px;
    }
}